body {
  background-color: #989c94;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header__txt {
  padding: 0 50px 0 0;
}

main {
  width: 100%;
  height: 100%;
}

.thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.thumbnail__img {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 230px;
  height: 160px;
}
.thumbnail__img:hover {
  border: 4px solid #317afb;
  cursor: grab;
}

.thumbnail_txt {
  font-size: 17px;
  text-align: center;
  margin: 0;
}
.thumbnail_txt:hover {
  font-weight: bold;
  color: #317afb;
  cursor: grab;
}

.img__display {
  width: 96%;
  height: calc((100vh - 295px) * 0.98);
  object-fit: contain;
  margin: 0 5px 0 px;
}
 
.img__thumbnail {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.selector {
  margin: 20px 10px 20px 10px;
  font-size: 20px;
  > label {
    margin: 0 10px 0 10px;
  }
  > select {
    font-size: 20px;
    max-width: 90vw;
  }
}

.description {
  margin: 0 10px 20px 10px;
  font-size: 12px;
  text-align: center;
  padding: 0 20px 0 20px;
}
